<template>
  <PageContainer>
    <template slot="title">
      <!-- NOTE: 标题栏: 开发人员根据业务调整 -->
      <pageTitle pageTitle="UI规范列表页无tab演示标题">
        <div slot="content">
          1.底色（白色）统一，通到页面底部。不再区分有tab和无tab情况。
          <br />
          2.列表标题上下间距统一，与面包屑padding：16px;与列表padding：16px。
          <br />
          3.所有文字链接、文字按钮颜色统一。
          <br />
          4.列表页输入框、按钮高度36px,设置 size="medium" 即可。
          <br />
          5.内容区:按钮组合与tab距离padding：16px;内容区与表头padding：16px;横排按钮间间距是12px;
          <br />
          6. tab高度48px。
          <br />
          7. 内容区域：无tab时padding24px;有tab时顶部padding设置0px，其余24px;
          <br />
          8.列表内图片固定高度70px；宽度根据图片展示比例调整：描边1px:#f0f0f0; 弧度4px;
          <br />
          9.操作项内，当前不可用按钮状态不可去掉，置灰显示即可。
          <br />
          10.列表表头高度48px, 默认vh-table高度即可。
          <br />
          11.时间一行显示。
        </div>
        <HelpPopover />
      </pageTitle>
    </template>

    <!-- 内容区 -->
    <template slot="content">
      <BothContent2 />
    </template>
  </PageContainer>
</template>

<script>
  import HelpPopover from './HelpPopover.vue';
  import PageContainer from '@/components/PageContainer';
  import PageTitle from '@/components/PageTitle';
  import PageListBox from '@/components/PageListBox';
  import BothContent2 from './BothContent2.vue';

  export default {
    components: {
      HelpPopover,
      PageContainer,
      PageTitle,
      BothContent2
    }
  };
</script>
